import { resolveDirective as _resolveDirective, createTextVNode as _createTextVNode, withDirectives as _withDirectives, vShow as _vShow, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
import "core-js/modules/es.array.push.js";
import BindRole from './bindRole';
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "Permission",
  mixins: [mixins],
  components: {
    BindRole
  },
  data() {
    return {
      currentPermission: null,
      bindRoleDialog: false,
      columns: [{
        label: '权限名称',
        align: 'left',
        customRender: props => {
          if (props.row.icon && props.row.icon.startsWith('http')) {
            return _createVNode("span", null, [_createVNode("img", {
              "class": "menu-image",
              "src": props.row.icon
            }, null), props.row.permissionName]);
          } else {
            var _props$row$icon;
            return _createVNode("span", null, [_withDirectives(_createVNode(_resolveComponent("el-icon"), {
              "class": ["iconfont", (_props$row$icon = props.row.icon) !== null && _props$row$icon !== void 0 ? _props$row$icon : 'icon-menu'],
              "size": "16px",
              "style": "margin-right:5px"
            }, null), [[_vShow, props.row.type === '菜单']]), props.row.permissionName]);
          }
        }
      }, {
        prop: 'permissionCode',
        label: '权限编码'
      }, {
        prop: 'type',
        label: '类型'
      }, {
        label: '已绑定API',
        customRender: props => {
          if (props.row.requestPaths) {
            const result = [];
            props.row.requestPaths.forEach(item => result.push(`${item.name}(${item.url})`));
            return result.join();
          } else {
            return _withDirectives(_createVNode(_resolveComponent("el-button"), {
              "type": "primary",
              "link": true,
              "onClick": () => {
                this.loadRequestPaths(props.row);
              }
            }, {
              default: () => [_createTextVNode("\u67E5\u770B")]
            }), [[_resolveDirective("permission"), 'permission_request_path']]);
          }
        }
      }],
      topButtons: [{
        name: '新增',
        code: 'permission_add',
        type: 'primary',
        click: this.permissionAdd,
        permissions: ['permission_add']
      }],
      linkButtons: [{
        name: '删除',
        code: 'permission_del',
        click: this.permissionDel,
        permissions: ['permission_del']
      }, {
        name: '修改',
        code: 'permission_edit',
        click: this.permissionEdit,
        permissions: ['permission_edit']
      }, {
        name: '绑定角色',
        code: 'permission_bind_role',
        click: this.permissionBindRole,
        permissions: ['permission_bind_role']
      }]
    };
  },
  methods: {
    permissionAdd() {
      this.$router.push('/permissionAdd');
    },
    permissionDel(row) {
      ElMessageBox.confirm('确认要删除该权限定义吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.uac.permission.del(row.permissionId).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    permissionEdit(row) {
      this.$router.push({
        path: '/permissionAdd',
        query: {
          permissionId: row.permissionId
        }
      });
    },
    permissionBindRole(row) {
      this.currentPermission = row;
      this.bindRoleDialog = true;
    },
    bindRole(permissionId, selectedRoleIds) {
      const loading = ElLoading.service();
      this.$api.uac.permission.bindRole(permissionId, selectedRoleIds).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.bindRoleDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    loadRequestPaths(row) {
      const loading = ElLoading.service();
      this.$api.uac.requestPath.listByPermission(row.permissionId).then(res => {
        if (res.code === 200) {
          row.requestPaths = res.data;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};