import "core-js/modules/es.array.push.js";
import { ElLoading } from 'element-plus';
import 'element-plus/es/components/loading/style/css'

export default {
  name: "permissionBindRole",
  props: ['permission'],
  emits: ['on-close', 'on-submit'],
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      roles: [],
      selectedRoleIds: []
    };
  },
  methods: {
    handleCheckAllChange(checkAll) {
      this.selectedRoleIds = [];
      if (checkAll) {
        this.roles.forEach(role => {
          this.selectedRoleIds.push(role.roleId);
        });
        this.isIndeterminate = false;
      }
    },
    handleCheckedColumnIndexChange(value) {
      this.isIndeterminate = value.length > 0 && value.length < this.roles.length;
      this.checkAll = value.length === this.roles.length;
    },
    submit() {
      this.$emit('on-submit', this.permission.permissionId, this.selectedRoleIds);
    },
    cancel() {
      this.$emit('on-close');
    }
  },
  beforeMount() {
    const loading = ElLoading.service();
    Promise.all([this.$api.uac.role.queryByPermission(this.permission.permissionId), this.$api.uac.role.list()]).then(res => {
      if (res[0].code === 200 && res[1].code === 200) {
        this.roles = res[1].data;
        if (res[0].data) {
          res[0].data.forEach(role => this.selectedRoleIds.push(role.roleId));
        }
        this.isIndeterminate = this.selectedRoleIds.length > 0 && this.selectedRoleIds.length < this.roles.length;
        this.checkAll = this.selectedRoleIds.length === this.roles.length;
      }
    }).finally(() => {
      loading.close();
    });
  }
};